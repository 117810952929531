import * as React from "react"
import { FC, useState } from "react"
import { ToggleButton } from "./ToggleButton"
import { Navigation } from "./Navigation"
import "./header.css"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

const Header: FC = () => {
  const [open, setOpen] = useState(false)
  const toggleFunction = () => {
    setOpen(prevState => !prevState)
  }

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const title = data.site.siteMetadata.title || `Title`

  return (
    <header className="site-header">
      <div className="wrapper site-header__wrapper">
        <Link to="/" className="brand">
          {title}
        </Link>
        <nav className="nav">
          <ToggleButton
            open={open}
            controls="navigation"
            label="メニューを開きます"
            onClick={toggleFunction}
          />
          <Navigation open={open} />
        </nav>
      </div>
    </header>
  )
}
export default Header
