import React from "react"
import { graphql, useStaticQuery } from "gatsby"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const sitename = data.site.siteMetadata?.title || `Title`

  return (
    <footer>
      <p className="footer-reserved">
        <small>
          {currentYear} &copy; {sitename} All Right Reserved.
        </small>
      </p>
    </footer>
  )
}

export default Footer
