import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TermsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <main>
        <div className="cntWrapper">
          <ol className="breadcrumb">
            <li>
              <a href="/">TOP</a>
            </li>
            <li>特定商取引法に基づく表示</li>
          </ol>
          <div className="cntWrapperIndex">
            <div className="mainnav">
              <article className="post">
                <h1>特定商取引法に基づく表示</h1>
                <div class="trade-law-info">
                  <h3>運営者</h3>
                  <p>屋号: Jamstackホームページ制作屋</p>
                  <p>代表者: 中野智貴</p>
                  <p>住所: 東京都新宿区西新宿 4-39-1 宮沢ハイツ 205</p>
                  <p>メールアドレス: jamstackdown@gmail.com</p>
                  <h3>商品等の名称</h3>
                  <li>Webサイト作成</li>
                  <li>サーバー運用代行</li>
                  <h3>販売価格</h3>
                  <p>
                    Webサイト作成代行：プロジェクトの要件により異なります。具体的な費用は、お客様の要求に基づいて個別に見積もりを作成し、それを基に決定します。
                  </p>
                  <p>
                    サーバー運用代行：価格はサービス内容により異なります。詳細は個別にお見積りいたします。
                  </p>
                  <h3>必要となる費用</h3>
                  <p>
                    これらもプロジェクトの要件により異なるため、見積もりを基に決定します。
                  </p>

                  <h3>支払方法と支払時期</h3>
                  <p>
                    支払い方法：クレジットカード、銀行振込（その他の支払い方法がある場合はここに追加）
                  </p>
                  <p>支払い時期：各月15日締めで、当月中に支払い</p>
                  <h3>商品引渡し時期</h3>
                  <p>
                    Webサイト作成代行：契約後、プロジェクトの規模と複雑さに応じて、完成次第納品します。具体的な納期はプロジェクトの進行状況によりますが、進捗状況は定期的にお客様に報告します。
                  </p>
                  <p>
                    サーバー運用代行：契約後、設定等が完了次第サービスを開始します。具体的な開始時期は契約内容や設定状況によります。
                  </p>
                  <h3>返品・キャンセル・交換について</h3>
                  <p>
                    本サービスは一度提供・実施した後の返品・交換は原則として受け付けておりません。
                  </p>
                  <p>
                    本サービスをキャンセルされる際は、お客様のご都合によるキャンセルの場合、キャンセル料を申し受けます。キャンセル料は、ご契約金額の50%とさせていただきます。
                  </p>
                  <p>
                    月額のサーバー運用を終了したい場合は、メールにてご連絡してください、請求とサーバーを一ヶ月以内に停止いたします。
                  </p>

                  <h3>プライバシーポリシーとセキュリティ対策</h3>
                  <p>
                    当社は、お客様の個人情報の保護を重視し、その安全性確保のための適切な対策を行っております。お客様からお預かりした情報は、厳重に管理し、外部からの不正アクセス、情報の漏洩、改ざん、損失等を防ぐため、適切なセキュリティ対策を実施しております。
                  </p>
                  <p>
                    クレジットカード情報については、PCI
                    DSS準拠の決済代行会社を通じて適切に処理され、当社のシステム・サーバー等には一切保存されません。
                  </p>
                  <p>
                    以上の取組みを通じて、お客様からお預かりした個人情報の安全管理に努めてまいります。
                  </p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default TermsPage

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="特定商取引法に基づく表示"
    description="Jamstackホームページ制作屋の特定商取引法に基づく表示"
    cover={"/work.png"}
    noindex={true}
  />
)

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
