import React from "react"
import Footer from "./footer"
import Header from "../components/header/header"

const Layout = ({ children }) => {
  return (
    <div>
      <div>
        <Header />
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
