import * as React from "react"
import { FC } from "react"
import { Link } from "gatsby"

type Props = {
  open: boolean
}

export const Navigation: FC<Props> = ({ open }) => {
  return (
    <ul className="nav__wrapper" aria-hidden={!open}>
      <li className="nav__item">
        <Link to="/flow/">制作の流れ</Link>
      </li>
      <li className="nav__item">
        <Link to="/price/">料金表</Link>
      </li>
      <li className="nav__item">
        <Link to="/catalog/">カタログ</Link>
      </li>
      <li className="nav__item">
        <Link to="/contact/">お問い合わせ</Link>
      </li>
    </ul>
  )
}
